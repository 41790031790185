<template>
  <van-config-provider :theme-vars="themeVars">
    <div class="ticket-warp" :style="`background-image: url(${ticket.screenshot})`">
      <div class="content my-3">
        <h4>{{ ticket.name }}</h4>
        <div>{{ $t('rate') }} <van-rate v-model="ticket.scenic_spot.score" void-color="#C4C4C4" color="#A5D63F" readonly /></div>
        <div class="mt-2">
          <van-button v-if="!ticket.is_collect" icon="star-o" size="small" @click="handleFavorite">{{ $t('favorite')
            }}</van-button>
          <van-button v-else icon="star" size="small" @click="handleUnfavorite">{{ $t('favorite') }}</van-button>
          &nbsp;
          <van-button v-if="!ticket.is_like" icon="like-o" size="small" @click="handleLike">{{ $t('like')
            }}</van-button>
          <van-button v-else icon="like" size="small" @click="handleUnlike">{{ $t('like') }}</van-button>
        </div>
        <div class="van-hairline--top mt-3"></div>
        <div class="py-3"><b>{{ $t("spot_introduction") }}</b></div>
        <div v-if="ticket.scenic_spot.preview_list.length > 0" class="preview_list d-flex">
          <template v-for="(url, index) in ticket.scenic_spot.preview_list" :key="index">
            <van-image v-if="index < 6" width="50px" height="50px" radius="15px" :src="url" @click="showPic(index)"></van-image>
          </template>
        </div>
        <div v-if="!state.showmore" class="van-multi-ellipsis--l2">
          {{ stripTags(ticket.scenic_spot.description) }}
        </div>
        <div v-else class="description" v-html="ticket.scenic_spot.description"></div>
        <div class="text-center text-muted py-2" @click="state.showmore = !state.showmore">
          <template v-if="!state.showmore">
            <span>{{ $t("show_more") }}</span>
            <van-icon name="arrow-down" />
          </template>
          <template v-else>
            <span>{{ $t("show_less") }}</span>
            <van-icon name="arrow-up" />
          </template>
        </div>
        <div class="van-hairline--top mt-3"></div>
        <div class="scetion py-3">
          <div class="label">{{ $t("ai_npc") }}</div>
          <div v-if="ticket.npc_list" class="d-flex align-items-center mt-2">
            <div v-for="(item, index) in ticket.npc_list" :key="index" class="m-2">
              <van-image :src="item.avatar" width="50px" height="50px" round />
              <div class="mt-2">{{ item.name }}</div>
            </div>
          </div>
          <van-empty v-else :image="icons.logo" image-size="100" description="NO NPC" />
        </div>
        <div class="van-hairline--top mt-3"></div>
        <div class="scetion py-3">
          <div class="label"><van-icon :name="icons.timer" /> {{ $t("play_time_limit") }}</div>
          <div class="value">{{ $t('hour', 24) }}</div>
        </div>
        <div class="van-hairline--top mt-3"></div>
        <div class="scetion py-3">
          <div class="label"><van-icon :name="icons.checkticket" /> {{ $t("ticket_checking_station") }}</div>
          <div class="value">
            <TicketCheckingStation />
          </div>
        </div>
        <div class="van-hairline--top mt-3"></div>
        <div class="scetion py-3">
          <div class="label"><van-icon :name="icons.user" /> {{ $t("terms_and_conditions") }}</div>
          <div class="value" v-html="state.buyer_tips"></div>
        </div>
        <van-action-bar>
          <van-button type="primary" class="mx-2" block :text="$t('buy_with_price', {price: ticket.price})"
            @click="$router.push({ name: 'TicketPruchase', params: { id: ticket.id } })" />
        </van-action-bar>
      </div>
    </div>
    <van-tabbar>
      <van-tabbar-item name="home" @click="$router.push({ name: 'Home' })">{{ $t("more_scenic_spot") }}</van-tabbar-item>
      <van-tabbar-item name="user" @click="$router.push({ name: 'UserProfile' })">{{ $t("profile") }}</van-tabbar-item>
    </van-tabbar>
  </van-config-provider>
</template>

<script>
import { Toast } from 'vant';
import { ActionBar, Rate, Empty, ImagePreview } from 'vant';
import { getTicketDetail } from "@/api/ticket.service";
import { like, unlike, favorite, unfavorite } from "@/api/scenic.service";
import { getOption } from "@/api/option.service";
import { onMounted, reactive } from "@vue/runtime-core";
import { stripTags } from "@/utils/util";
import TicketCheckingStation from "@/components/TicketCheckingStation";
import { useI18n } from "vue-i18n";
export default {
  props: {
    id: {
      type: String,
      require: true,
    }
  },
  components: {
    [ActionBar.name]: ActionBar,
    [Empty.name]: Empty,
    [Rate.name]: Rate,
    [ImagePreview.Component.name]: ImagePreview.Component,
    TicketCheckingStation,
  },
  setup(props) {
    const { t } = useI18n({ useScope: "global" });
    const state = reactive({ showmore: false, buyer_tips: "" });
    const ticket = reactive({ id: 0, price: 0, scenic_spot: { score: 0, description: "", preview_list: [] } });
    const icons = {
      timer: require("@/assets/images/icons/timer.svg"),
      checkticket: require("@/assets/images/icons/checkticket.svg"),
      user: require("@/assets/images/icons/user.svg"),
      logo: require("@/assets/images/icons/logo.svg"),
    };

    const themeVars = {
      // tabsBottomBarColor: "#222",
      // buttonPrimaryBackgroundColor: "#222",
      // buttonPrimaryBorderColor: "#222",
      cardThumbSize: "66px",
    };


    const loadDetail = async (id) => {
      try {
        const { result } = await getTicketDetail(id);
        Object.assign(ticket, result);
        if (ticket.scenic_spot.preview_list) {
          ticket.scenic_spot.preview_list = JSON.parse(ticket.scenic_spot.preview_list);
        }
        ticket.scenic_spot.score = parseFloat(ticket.scenic_spot.score);
        window.localStorage.setItem("seller_id", result.user_id);
      } catch (error) {
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        if (!msg) console.log(error);
        const message = msg ? msg : t("front_end_error");
        Toast.fail(message);
      }
    };

    const loadBuyerTips = async () => {
      try {
        const { result } = await getOption('h5_buyer_tips');
        state.buyer_tips = result.option_value;
      } catch (error) {
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        Toast({ message: msg ? msg : t("front_end_error"), position: "bottom" });
        if (!msg) console.log(error);
        state.buyer_tips = "加载失败";
      }
    };

    onMounted(() => {
      loadDetail(props.id);
      loadBuyerTips();
    });

    const showPic = (index) => {
      ImagePreview({
        images: ticket.scenic_spot.preview_list,
        startPosition: index,
      })
    };

    const checkLogin = () => {
      // 在操作点赞收藏时，如果未登录标记登录后的返回地址
      const currentUser = JSON.parse(window.localStorage.getItem("currentUser"));
      if (!currentUser) {
        window.sessionStorage.setItem("login_redirect_url", window.location.href);
      }
    };

    const handleFavorite = async () => {
      try {
        checkLogin();
        await favorite(ticket.scenic_spot_id);
        Toast.success(t('favorite_successful'));
        ticket.is_collect = 1;
      } catch (error) {
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        Toast({ message: msg ? msg : t("front_end_error"), position: "bottom" });
        if (!msg) console.log(error);
      }
    };

    const handleUnfavorite = async () => {
      try {
        checkLogin();
        await unfavorite(ticket.scenic_spot_id);
        Toast.success(t('favorite_canceled'));
        ticket.is_collect = 0;
      } catch (error) {
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        Toast({ message: msg ? msg : t("front_end_error"), position: "bottom" });
        if (!msg) console.log(error);
      }
    };

    const handleLike = async () => {
      try {
        checkLogin();
        await like(ticket.scenic_spot_id);
        Toast.success(t('like_successful'));
        ticket.is_like = 1;
      } catch (error) {
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        Toast({ message: msg ? msg : t("front_end_error"), position: "bottom" });
        if (!msg) console.log(error);
      }
    };

    const handleUnlike = async () => {
      try {
        checkLogin();
        await unlike(ticket.scenic_spot_id);
        Toast.success(t('like_canceled'));
        ticket.is_like = 0;
      } catch (error) {
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        Toast({ message: msg ? msg : t("front_end_error"), position: "bottom" });
        if (!msg) console.log(error);
      }
    };

    return { icons, ticket, state, themeVars, stripTags, showPic, handleFavorite, handleUnfavorite, handleLike, handleUnlike };
  }
}
</script>

<style lang="less" scoped>
.ticket-warp {
  width: 100vw;
  min-height: 100vh;
  padding: 0;
  vertical-align: middle;
  background-color: #fff;
  padding-top: 140px;
  background-position: top;
  background-size: contain;
  background-repeat: no-repeat;

  .content {
    background-color: #fff;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    min-height: 100vh;
    padding: 10px 20px 120px 20px;

    .description {
      overflow: hidden;

      img {
        width: 100% !important;
        height: auto !important;
      }

      p {
        img {
          width: 100% !important;
          height: auto !important;
        }
      }
    }

    .van-action-bar {
      bottom: 60px;
    }

    .van-button {
      border-radius: 20px;
    }

    .preview_list {
      margin: 10px 0;

      .van-image {
        margin-right: 10px;
      }
    }

    .scetion {

      .label {
        color: #969799;
      }

      .value {
        margin-left: 20px;
        margin-top: 10px;
      }
    }
  }
}
</style>
